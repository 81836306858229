import { useEffect } from "react";
import { PlotType } from "../../../../data/plot-types";
import { RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { useChart } from "../../../../taskpane/hooks/plots/useCentralViz";
import { dependencyEqualsValue, dependencyNotEqualsValue, fetchHeaders } from "../MVCShared/PlotGeneratorUtils";
import { Chart } from "../MVCShared/types";
import {
  DataRangeAttr,
  DividerAttr,
  DropdownAttr,
  HeadingAttr,
  LabelAttr,
  PaletteAttr,
  CheckBoxAttr,
  SpinnerAttr,
  ColorPickerAttr,
  CollapsibleAttr,
  AxisDropdownAttr,
  GridlinesAttr,
  SingleChartBorders,
} from "../MVCShared/PlotAttributes";
import { buildReactFromAttrs, buildCode } from "../MVCShared/CodeBuilder";
import { HistSetup, HistDesign as IHistogramDesign } from "src/taskpane/hooks/plots/useHistogram";

const HistogramContext = (): Chart => {
  const { setup, design, common, updateDesign, updateSetup, updateCommon, setCodeFragments } = useChart<
    HistSetup,
    IHistogramDesign
  >(PlotType.histogram);

  useEffect(() => {
    (async () => {
      const cb = await buildCode(common, [...HistogramForm.baseAttrs, ...HistogramForm.designAttrs]);
      setCodeFragments(cb);
    })();
  }, [setup, design, common]);

  useEffect(() => {
    (async () => {
      await fetchHeaders(common.inputData, common.hasHeaders, updateSetup);
    })();
  }, [common.hasHeaders, common.inputData]);

  const xAxis = AxisDropdownAttr({
    value: setup.xAxis,
    onChange: (_, data) => {
      updateSetup({ xAxis: data.optionValue }, "--Select--");
      updateDesign({ xAxisLabel: data.optionValue }, "--Select--");
    },
    label: "X-Axis",
    options: ["--Select--", ...setup.headers],
    codeKey: "x",
    hasHeaders: common.hasHeaders,
  });

  // Specific rendering for custom
  const ColorBy = AxisDropdownAttr({
    value: setup.colorBy,
    onChange: (_, data) => updateSetup({ colorBy: data.optionValue }, "--None--"),
    label: "Color By",
    options: ["--None--", ...setup.headers],
    placeholder: "--None--",
    codeKey: "hue",
    hasHeaders: common.hasHeaders,
  });

  const HistogramForm: Chart = {
    baseAttrs: [
      DataRangeAttr({
        inputData: common.inputData,
        onChangeSelection: (newSelection: RangeSelection) => updateCommon({ inputData: newSelection }),
      }),
      CheckBoxAttr({
        label: "Has headers",
        value: common.hasHeaders,
        onChange: (_, e) => updateCommon({ hasHeaders: e.checked }),
        dataTestID: "headers",
      }),
      DividerAttr(),
      HeadingAttr({
        title: "Data",
        tooltip: "Select data cells and parameters",
      }),
      xAxis,
      AxisDropdownAttr({
        value: setup.yAxis,
        onChange: (_, data) => {
          updateSetup({ yAxis: data.optionValue }, "--Select--");
          updateDesign({ yAxisLabel: data.optionValue }, "--Select--");
        },
        label: "Y-Axis",
        options: ["--Select--", ...setup.headers],
        codeKey: "y",
        hasHeaders: common.hasHeaders,
      }),
      AxisDropdownAttr({
        value: setup.weights,
        onChange: (_, data) => {
          updateSetup({ weights: data.optionValue }, "--Select--");
        },
        label: "Weights",
        options: ["--Select--", ...setup.headers],
        codeKey: "weights",
        hasHeaders: common.hasHeaders,
      }),
      DividerAttr(),
      DropdownAttr({
        label: "Statistic",
        options: ["Count", "Frequency", "Probability", "Percent", "Density"],
        value: setup.stat,
        codeKey: "stat",
        codeValueMap: {
          Count: "count",
          Frequency: "frequency",
          Probability: "probability",
          Percent: "percent",
          Density: "density",
        },
        codeRequiresInteraction: true,
        onChange: (_, data) => updateSetup({ stat: data.optionValue }),
      }),
      DividerAttr(),
      HeadingAttr({ title: "Grouping", tooltip: "Determine violin color" }),
      ColorBy,
      DropdownAttr({
        label: "Multiple",
        options: ["Layer", "Stack", "Fill"],
        value: setup.multiple,
        codeKey: "multiple",
        codeValueMap: { Layer: "layer", Stack: "stack", Fill: "fill" },
        codeRequiresInteraction: true,
        onChange: (_, data) => updateSetup({ multiple: data.optionValue }),
      }),
      DropdownAttr({
        label: "Element",
        options: ["Bars", "Step", "Poly"],
        value: setup.element,
        codeKey: "element",
        codeValueMap: { Bars: "bars", Step: "step", Poly: "poly" },
        codeRequiresInteraction: true,
        onChange: (_, data) => updateSetup({ element: data.optionValue }),
      }),
      DividerAttr(),
      //   CollapsibleAttr({
      //     label: "Kernel Density Estimate",
      //     tooltip: "Compute kernel density estimate",
      //     collapsed: setup.isCollapsed,
      //     toggle: () => updateSetup({ isCollapsed: !setup.isCollapsed }),
      //     children: [
      CheckBoxAttr({
        value: setup.kde,
        onChange: (_, e) => updateSetup({ kde: e.checked }),
        label: "KDE",
        codeKey: "kde",
        codeRequiresInteraction: true,
      }),
      // ],
      //   }),
      DividerAttr(),
      DropdownAttr({
        label: "Bins",
        options: ["Auto", "Doane", "Scott"],
        value: setup.bins,
        codeKey: "bins",
        codeValueMap: { Auto: "auto", Doane: "doane", Scott: "scott" },
        codeRequiresInteraction: true,
        onChange: (_, data) => updateSetup({ bins: data.optionValue }),
      }),
      CheckBoxAttr({
        value: setup.cumulative,
        onChange: (_, e) => updateSetup({ cumulative: e.checked }),
        label: "Cumulative",
        codeKey: "cumulative",
        codeRequiresInteraction: true,
      }),
    ],
    designAttrs: [
      LabelAttr({
        value: design.plotTitle,
        placeholder: "Title",
        label: "Title",
        codeKey: "title",
        onChange: (event) => updateDesign({ plotTitle: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.xAxisLabel,
        placeholder: "Defaults header",
        label: "X-Axis Label",
        codeKey: "xlabel",
        onChange: (event) => updateDesign({ xAxisLabel: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.yAxisLabel,
        placeholder: "Defaults header",
        label: "Y-Axis Label",
        codeKey: "ylabel",
        onChange: (event) => updateDesign({ yAxisLabel: event.currentTarget.value }),
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.borderCollapsed,
        label: "Border",
        toggle: () => {
          updateDesign({ borderCollapsed: !design.borderCollapsed });
        },
        children: [
          SingleChartBorders({
            label: "Top",
            value: design.topSpine,
            onChange: (_, e) => updateDesign({ topSpine: e.checked }),
            callKey: "top",
          }),
          SingleChartBorders({
            label: "Right",
            value: design.rightSpine,
            onChange: (_, e) => updateDesign({ rightSpine: e.checked }),
            callKey: "right",
          }),
          SingleChartBorders({
            label: "Bottom",
            value: design.bottomSpine,
            onChange: (_, e) => updateDesign({ bottomSpine: e.checked }),
            callKey: "bottom",
          }),
          SingleChartBorders({
            label: "Left",
            value: design.leftSpine,
            onChange: (_, e) => updateDesign({ leftSpine: e.checked }),
            callKey: "left",
          }),
        ],
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.gridlinesCollapsed,
        label: "Gridlines",
        toggle: () => {
          updateDesign({ gridlinesCollapsed: !design.gridlinesCollapsed });
        },
        children: [
          GridlinesAttr({
            majorHorizontal: design.majorHorizontal,
            majorVertical: design.majorVertical,
            minorHorizontal: design.minorHorizontal,
            minorVertical: design.minorVertical,
            onChange: (key, event) => {
              updateDesign({ [key]: event.checked });
            },
          }),
        ],
      }),
      DividerAttr(),
      HeadingAttr({ title: "Axes Label Rotation" }),
      SpinnerAttr({
        label: "X-Ticks",
        value: design.xticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (data: number) => updateDesign({ xticks: data }),
        callKey: "xticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Y-Ticks",
        value: design.yticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (data: number) => updateDesign({ yticks: data }),
        callKey: "yticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      ColorPickerAttr({
        value: design.color,
        label: "Color",
        onChange: (hexColor) => updateDesign({ color: `#${hexColor}` }),
        codeKey: "color",
        visibleDependencies: [dependencyEqualsValue(ColorBy, "")],
        codeRequiresInteraction: true,
      }),
      PaletteAttr({
        value: design.palette,
        onChange: (_, data) => updateDesign({ palette: data.optionText }),
        codeKey: "palette",
        placeholder: "Accent",
        visibleDependencies: [dependencyNotEqualsValue(ColorBy, "")],
        codeRequiresInteraction: true,
      }),
      CheckBoxAttr({
        value: design.fill,
        onChange: (_, e) => updateDesign({ fill: e.checked }),
        label: "Fill",
        codeKey: "fill",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      CheckBoxAttr({
        value: design.legend,
        onChange: (_, e) => updateDesign({ legend: e.checked }),
        label: "Legend",
        codeKey: "legend",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
    ],
  };
  return HistogramForm;
};

const HistogramForm = () => {
  const HistogramForm = HistogramContext();
  return buildReactFromAttrs(HistogramForm.baseAttrs, 100);
};

export const HistogramDesign = () => {
  const HistogramDesign = HistogramContext();
  return buildReactFromAttrs(HistogramDesign.designAttrs, 120);
};

export default HistogramForm;
