import { BaseDesign, BaseSetup } from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";
import styles from "../../styles.module.scss";

export interface KdeSetup extends BaseSetup {
  // TODO: specifics
  bwMethod: ValueTracker<string>;
  bwAdjust: ValueTracker<number>;
  multiple: ValueTracker<string>;
  cut: ValueTracker<number>;
  weights: string;
  gridSize: ValueTracker<number>;
  logScale: ValueTracker<number>;
  isCollapsed: boolean;
}

export interface KdeDesign extends BaseDesign {
  // TODO: specifics
  legend: boolean;
  yticks: ValueTracker<number>;
  xticks: ValueTracker<number>;
  thresh: ValueTracker<number>;
  levels: ValueTracker<number>;
  isCollapsed: boolean;
}

export const kdeSetup: KdeSetup = {
  headers: [],
  xAxis: "",
  yAxis: "",
  weights: "",
  colorBy: "",
  bwMethod: new ValueTracker("Scott"),
  bwAdjust: new ValueTracker(1),
  multiple: new ValueTracker("Layer"),
  cut: new ValueTracker(3),
  gridSize: new ValueTracker(200),
  isCollapsed: true,
  logScale: new ValueTracker(10),
  // if color by scale color should be true
};

export const kdeDesign: KdeDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  fill: new ValueTracker(true),
  color: new ValueTracker(styles.seabornDefault),
  palette: new ValueTracker("Tab10"),
  thresh: new ValueTracker(0.05),
  legend: false,
  yticks: new ValueTracker(0),
  xticks: new ValueTracker(0),
  isCollapsed: true,
  majorHorizontal: new ValueTracker(false),
  majorVertical: new ValueTracker(false),
  minorHorizontal: new ValueTracker(false),
  minorVertical: new ValueTracker(false),
  leftSpine: true,
  bottomSpine: true,
  topSpine: false,
  rightSpine: false,
  borderCollapsed: true,
  gridlinesCollapsed: true,
  levels: new ValueTracker(10),
};
