import React from "react";
import { LockClosed24Regular } from "@fluentui/react-icons";
import { CountDesign } from "./Plots/CountPlotMVC";
import { BarDesign } from "./Plots/BarPlotMVC";
import { BoxDesign } from "./Plots/BoxPlotMVC";
import { ScatterDesign } from "./Plots/ScatterPlotMVC";
import { LineDesign } from "./Plots/LinePlotMVC";
import { RegressionDesign } from "./Plots/RegressionPlotMVC";
import { ViolinDesign } from "./Plots/ViolinPlotMVC";
import { buildPythonInExcelCode } from "./MVCShared/CodeBuilder";
import { getFullAddressFromRangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { writeToExecutionLocation } from "../../../excel/grid-utils";
import ActionButtons from "./ActionButtons";
import { PairDesign } from "./Plots/PairPlot";
import { DisplotDesign } from "./Plots/Displot";
import { useChart, useChartManager } from "../../../taskpane/hooks/plots/useCentralViz";
import { BaseDesign as IBaseDesign, BaseSetup as IBaseSetup } from "../../../taskpane/hooks/plots/PlotTypes";
import { PlotType } from "../../../data/plot-types";
import { CustomDialog } from "../../../taskpane/components/Dialogs/CustomDialog";
import { LockedLabel } from "./DialogLabels";
import { KdeDesign } from "./Plots/KdePlot";
import { HistogramDesign } from "./Plots/Histogram";
import { ECDFDesign } from "./Plots/ECDF";

const FormComponentMap: Record<PlotType, () => React.JSX.Element> = {
  [PlotType.bar]: BarDesign,
  [PlotType.box]: BoxDesign,
  [PlotType.count]: CountDesign,
  [PlotType.line]: LineDesign,
  [PlotType.regression]: RegressionDesign,
  [PlotType.scatter]: ScatterDesign,
  [PlotType.violin]: ViolinDesign,
  [PlotType.pairwise]: PairDesign,
  [PlotType.distribution]: DisplotDesign,
  [PlotType.kde]: KdeDesign,
  [PlotType.histogram]: HistogramDesign,
  [PlotType.ecdf]: ECDFDesign,
};

const BaseDesign = () => {
  const { plotType, setTab } = useChartManager();
  const { common, codeFragments, updateCommon, serializeContext } = useChart<IBaseSetup, IBaseDesign>(plotType);

  const handleCreateClick = async () => {
    const addr = await getFullAddressFromRangeSelection(common.outputCell);
    await writeToExecutionLocation(addr, await buildPythonInExcelCode(codeFragments));
    await serializeContext();
  };

  const SelectedFormComponent = FormComponentMap[plotType];

  return (
    <div className="base-design">
      <SelectedFormComponent />
      <ActionButtons onPrimaryClick={handleCreateClick} />
      <CustomDialog
        modalType="modal"
        title="Locked"
        buttonText="Edit Code"
        dismissText="Discard Code Edits"
        openDialog={!!common.typedCode}
        icon={<LockClosed24Regular />}
        content={<LockedLabel />}
        onContinue={() => setTab("code")}
        onCancel={() => updateCommon({ typedCode: undefined })}
      />
    </div>
  );
};

export default BaseDesign;
