import React from "react";
import BarForm from "./Plots/BarPlotMVC";
import CountForm from "./Plots/CountPlotMVC";
import BoxForm from "./Plots/BoxPlotMVC";
import ScatterForm from "./Plots/ScatterPlotMVC";
import LineForm from "./Plots/LinePlotMVC";
import RegressionForm from "./Plots/RegressionPlotMVC";
import ViolinForm from "./Plots/ViolinPlotMVC";
import ActionButtons from "./ActionButtons";
import { getFullAddressFromRangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { buildPythonInExcelCode } from "./MVCShared/CodeBuilder";
import { LockClosed24Regular } from "@fluentui/react-icons";
import PairForm from "./Plots/PairPlot";
import DisplotForm from "./Plots/Displot";
import { writeToExecutionLocation } from "../../../excel/grid-utils";
import { useChart, useChartManager } from "../../../taskpane/hooks/plots/useCentralViz";
import { BaseDesign as IBaseDesign, BaseSetup as IBaseSetup } from "../../../taskpane/hooks/plots/PlotTypes";
import { PlotType } from "../../../data/plot-types";
import { CustomDialog } from "../../../taskpane/components/Dialogs/CustomDialog";
import { LockedLabel } from "./DialogLabels";
import KdeForm from "./Plots/KdePlot";
import HistogramForm from "./Plots/Histogram";
import ECDFForm from "./Plots/ECDF";

const FormComponentMap: Record<PlotType, () => React.JSX.Element> = {
  [PlotType.bar]: BarForm,
  [PlotType.box]: BoxForm,
  [PlotType.count]: CountForm,
  [PlotType.line]: LineForm,
  [PlotType.regression]: RegressionForm,
  [PlotType.scatter]: ScatterForm,
  [PlotType.violin]: ViolinForm,
  [PlotType.pairwise]: PairForm,
  [PlotType.distribution]: DisplotForm,
  [PlotType.kde]: KdeForm,
  [PlotType.histogram]: HistogramForm,
  [PlotType.ecdf]: ECDFForm,
};

const BaseSetup = () => {
  const { plotType, setTab } = useChartManager();
  const { common, codeFragments, updateCommon, serializeContext } = useChart<IBaseSetup, IBaseDesign>(plotType);

  const handleCreateClick = async () => {
    const addr = await getFullAddressFromRangeSelection(common.outputCell);
    await writeToExecutionLocation(addr, await buildPythonInExcelCode(codeFragments));
    await serializeContext();
  };

  const SelectedFormComponent = FormComponentMap[plotType];

  return (
    <div className="base-setup">
      <SelectedFormComponent />
      <ActionButtons onPrimaryClick={handleCreateClick} />
      <CustomDialog
        modalType="modal"
        title="Locked"
        buttonText="Edit Code"
        dismissText="Discard Code Edits"
        openDialog={!!common.typedCode}
        icon={<LockClosed24Regular />}
        content={<LockedLabel />}
        onContinue={() => setTab("code")}
        onCancel={() => updateCommon({ typedCode: undefined })}
      />
    </div>
  );
};

export default BaseSetup;
