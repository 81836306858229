import { getGlobal } from "../global";
import { PyScriptWorker, WorkerInfo, BUILD_VERSION, WorkerResponse } from "./worker";
import { RuntimeStatus } from "./types";
import { PyWorkerConfig } from "./pytypes";
import { PyScriptLinkage } from "./types";

const DEBUG = false;

const g = getGlobal();

export interface PyWorkerInfo extends WorkerInfo {
  worker: PyWorker;
  config: PyWorkerConfig | (() => Promise<PyWorkerConfig>);
}

export interface PyWorker extends PyScriptWorker {
  loadPyScriptApps: (pkgs: string[]) => Promise<string | null>;
  micropipInstall: (pkgs: string[]) => Promise<string | null>;
  runUDF: (
    xlName: string,
    isNested: boolean,
    funcName: string,
    address: string,
    args: any[]
  ) => Promise<WorkerResponse>;
  evalCode: (code: string, linkage: PyScriptLinkage) => Promise<WorkerResponse>;
}

export async function getPyWorker(
  info: PyWorkerInfo,
  setRuntimeStatusFn?: (status: string) => void,
  customInit?: (py: PyWorker) => Promise<void>
): Promise<PyWorker> {
  if (info.worker === undefined) {
    if (DEBUG) console.log("info.worker is undefined. Creating new python worker");
    if (!info.loadingFlag) {
      // Grab the flag
      info.loadingFlag = true;
      // Wait for PyWorker to be available
      while (!g.PyWorker) {
        if (DEBUG) console.log("Waiting for PyWorker to be defined");
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    } else {
      // Wait for 0.5 seconds and try again
      if (DEBUG) console.log("Waiting for another thread to getPyWorker()");
      await new Promise((resolve) => setTimeout(resolve, 500));
      return await getPyWorker(info);
    }
    // Resolve any delayed settings
    if (typeof info.config === "function") {
      info.config = await info.config();
    }
    if (DEBUG) console.log(`info = ${JSON.stringify(info)}`);
    let rawWorker: any;
    try {
      rawWorker = await g.PyWorker(`/pyscript/static/pyworker/${BUILD_VERSION}/worker.py`, {
        version: info.config.pyodideVersion,
        config: {
          files: {
            [`/pyscript/static/pyworker/${BUILD_VERSION}/utils.py`]: "",
            [`/pyscript/static/pyworker/${BUILD_VERSION}/udf.py`]: "",
          },
        },
      });
    } catch (err) {
      console.log("error CAUGHT while calling window.PyWorker() !!!");
      console.error(err);
      throw err;
    }
    info.terminate = () => rawWorker.terminate();
    const py: PyWorker = rawWorker.sync;
    if (DEBUG) console.log("PyWorker created");

    // Pre-load packages
    await py.loadPackages(["setuptools"]);
    if (info.config.pyodidePackages?.length) {
      if (setRuntimeStatusFn) setRuntimeStatusFn(RuntimeStatus.LOADING_PYODIDE_PACKAGES);
      const error = await py.loadPackages(info.config.pyodidePackages);
      if (error) throw new Error(error);
    }

    if (info.config.pyscriptApps?.length) {
      if (setRuntimeStatusFn) setRuntimeStatusFn(RuntimeStatus.LOADING_PYSCRIPT_APPS);
      const error = await py.loadPyScriptApps(info.config.pyscriptApps);
      if (error) throw new Error(error);
    }

    if (info.config.micropipPackages?.length) {
      await py.loadPackages(["micropip"]);
      if (setRuntimeStatusFn) setRuntimeStatusFn(RuntimeStatus.LOADING_MICROPIP_PACKAGES);
      await py.micropipInstall(info.config.micropipPackages);
      const error = await py.micropipInstall(info.config.micropipPackages);
      if (error) throw new Error(error);
    }
    // Initialize
    if (setRuntimeStatusFn) setRuntimeStatusFn(RuntimeStatus.RUNNING_INIT_CODE);
    await py.initialize();
    if (customInit) {
      await customInit(py);
    } else {
      const resp = await py._runInit(info.config.initCode);
      if (resp.typeName === "error") {
        throw new Error(resp.error);
      }
    }

    info.worker = py;
  }
  return info.worker;
}
