import { BaseDesign, BaseSetup } from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";
import styles from "../../styles.module.scss";

export interface HistSetup extends BaseSetup {
  // TODO: specifics
  bw_method: ValueTracker<string>;
  bw_adjust: ValueTracker<number>;
  multiple: ValueTracker<string>;
  element: ValueTracker<string>;
  weights: string;
  stat: ValueTracker<string>;
  kde: ValueTracker<boolean>;
  bins: ValueTracker<string>;
  cumulative: ValueTracker<boolean>;
  common_bins: ValueTracker<boolean>;
  common_norm: ValueTracker<boolean>;
  shrink: ValueTracker<number>;
  logScale: ValueTracker<number>;
  isCollapsed: boolean;
}

export interface HistDesign extends BaseDesign {
  // TODO: specifics
  legend: ValueTracker<boolean>;
  yticks: ValueTracker<number>;
  xticks: ValueTracker<number>;
  thresh: ValueTracker<number>;
  pthresh: ValueTracker<number>;
  pmax: ValueTracker<number>;
  isCollapsed: boolean;
  binWidth: ValueTracker<number>;
}

export const histSetup: HistSetup = {
  headers: [],
  xAxis: "",
  yAxis: "",
  colorBy: "",
  weights: "",
  stat: new ValueTracker("Count"),
  bw_method: new ValueTracker("Scott"),
  bw_adjust: new ValueTracker(1),
  multiple: new ValueTracker("Layer"),
  element: new ValueTracker("Bars"),
  kde: new ValueTracker(false),
  bins: new ValueTracker("Auto"),
  cumulative: new ValueTracker(false),
  common_bins: new ValueTracker(true),
  common_norm: new ValueTracker(true),
  shrink: new ValueTracker(1),
  isCollapsed: true,
  logScale: new ValueTracker(10),
};

export const histDesign: HistDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  fill: new ValueTracker(true),
  color: new ValueTracker(styles.seabornDefault),
  palette: new ValueTracker("Tab10"),
  legend: new ValueTracker(true),
  yticks: new ValueTracker(0),
  xticks: new ValueTracker(0),
  isCollapsed: true,
  majorHorizontal: new ValueTracker(false),
  majorVertical: new ValueTracker(false),
  minorHorizontal: new ValueTracker(false),
  minorVertical: new ValueTracker(false),
  leftSpine: true,
  bottomSpine: true,
  topSpine: false,
  rightSpine: false,
  borderCollapsed: true,
  gridlinesCollapsed: true,
  binWidth: new ValueTracker(10),
  thresh: new ValueTracker(null),
  pthresh: new ValueTracker(null),
  pmax: new ValueTracker(null),
};
