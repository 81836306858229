import { BaseDesign, BaseSetup } from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";
import styles from "../../styles.module.scss";

export interface ECDFSetup extends BaseSetup {
  // TODO: specifics
  weights: string;
  stat: ValueTracker<string>;
  complementary: ValueTracker<boolean>;
  isCollapsed: boolean;
}

export interface ECDFDesign extends BaseDesign {
  // TODO: specifics
  legend: ValueTracker<boolean>;
  yticks: ValueTracker<number>;
  xticks: ValueTracker<number>;
  isCollapsed: boolean;
}

export const ecdfSetup: ECDFSetup = {
  headers: [],
  xAxis: "",
  yAxis: "",
  colorBy: "",
  weights: "",
  stat: new ValueTracker("Proportion"),
  complementary: new ValueTracker(false),
  isCollapsed: true,
  // if color by scale color should be true
};

export const ecdfDesign: ECDFDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  color: new ValueTracker(styles.seabornDefault),
  palette: new ValueTracker("Tab10"),
  legend: new ValueTracker(false),
  yticks: new ValueTracker(0),
  xticks: new ValueTracker(0),
  isCollapsed: true,
  majorHorizontal: new ValueTracker(false),
  majorVertical: new ValueTracker(false),
  minorHorizontal: new ValueTracker(false),
  minorVertical: new ValueTracker(false),
  leftSpine: true,
  bottomSpine: true,
  topSpine: false,
  rightSpine: false,
  borderCollapsed: true,
  gridlinesCollapsed: true,
};
