export const pythonEditorSetup = {
  //...basicSetup,
  allowMultipleSelections: true,
  lineNumbers: true,
  indentOnInput: true,
  dropCursor: true,
  drawSelection: true,
  autocompletion: true,
  history: true,
  highlightActiveLine: true,
  defaultKeymap: true,
  completionKeymap: true,
  lintKeymap: true,
  bracketMatching: true,
  closeBrackets: true,
  foldGutter: true,
  tabSize: 4,
};

export const rEditorSetup = {
  //...basicSetup,
  allowMultipleSelections: true,
  lineNumbers: true,
  indentOnInput: true,
  dropCursor: true,
  drawSelection: true,
  autocompletion: true,
  history: true,
  highlightActiveLine: true,
  defaultKeymap: true,
  completionKeymap: true,
  lintKeymap: true,
  bracketMatching: true,
  closeBrackets: true,
  foldGutter: true,
  tabSize: 2,
};
