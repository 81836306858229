export const BUILD_VERSION = "133";

export interface LogMsg {
  type: "info" | "warn" | "error";
  msg: string;
}

export interface WorkerRequest {
  address: string;
  code: string;
  mode: number;
  data: Record<string, any>;
}

export interface WorkerResponse {
  mode: number;
  typeName: string;
  preview?: any[][];
  logs?: LogMsg[];
  size?: number[];
  result?: any;
  error?: string;
}

export interface PyScriptWorker {
  setCloudData: (address: string, data: any) => Promise<void>;
  delCloudData: (address: string) => Promise<void>;
  loadPackages: (pkgs: string[]) => Promise<string | null>;
  _runInit: (initCode: string) => Promise<WorkerResponse>;
  resetKernel: (clearIsolated?: boolean) => Promise<void>;
  missingFromXLResults: (names: string[]) => Promise<string[]>;
  initialize: () => Promise<void>;
  runCode: (req: WorkerRequest) => Promise<WorkerResponse>;
}

export interface WorkerInfo {
  worker: PyScriptWorker;
  terminate: () => void;
  loadingFlag: boolean;
  config: {} | (() => Promise<{}>);
}
