/* eslint-disable prettier/prettier */
import React from "react";
import { ErrorBoundary } from "@sentry/react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { FluentProvider } from "@fluentui/react-components";
import { ArrowCircleRightRegular } from "@fluentui/react-icons";
import { lightTheme } from "./theme";
import {
  AIAssistant,
  ConnectToCloud,
  ConnectToWorkbook,
  DataConnections,
  Edublocks,
  NewProject,
  Profile,
  Start,
  Visualizations,
  CreateVisualization,
  Snippets,
  ExportSnippet,
  Settings,
} from "./pages";
import App from "./components/App";
import { FeedbackMenu } from "./components/FeedbackMenu";
import CoreViz from "./pages/Visualization/CoreViz";
import FilterPage from "./pages/Projects/Filter";
import ErrorPage from "./pages/Error";
import "../analytics/heap";
import "../analytics/sentry";
import { DataFiltersProvider } from "./hooks/useDataFilters";
import { PythonContextProvider } from "./hooks/usePython";
import "./index.scss";
import { BlocksProvider } from "./hooks/useBlocks";
import ChartProvider from "./hooks/plots/useCentralViz";
import { v4 as uuidv4 } from "uuid";

const STALE_TIME_MINUTES = 1;
const STALE_TIME_MS = STALE_TIME_MINUTES * 60 * 1000;

// useQueryClient to reference instance
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true, // Refetch on window focus if the data is stale
      staleTime: STALE_TIME_MS,
      retry: 1,
      // refetchOnMount: false,
    },
  },
});

/* Render application after Office initializes */
Office.onReady(() => {
  // Create react DOM root
  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

  // Abort everything if Excel is not defined
  try {
    Excel;
  } catch (err) {
    root.render(
      <div style={{ marginLeft: "10px" }}>
        <img src="/static/assets/toolbox-logo.svg" style={{ height: "64px", width: "64px", paddingTop: "10px" }} />
        <h2>Anaconda Toolbox must be run as an Excel add-in</h2>
        <h3>
          <ul style={{ listStyle: "none" }}>
            <li style={{ paddingTop: "20px" }}>
              <a
                href={`ms-excel:https://api.addins.store.office.com/addinstemplate/en-US/${uuidv4()}/WA200006518/none/AnacondaToolbox.xlsx?omexsrctype=1`}
                style={{ textDecoration: "none" }}
              >
                <ArrowCircleRightRegular /> Launch Toolbox in Excel Desktop
              </a>
            </li>
            <li style={{ paddingTop: "20px" }}>
              <a
                href="https://go.microsoft.com/fwlink/?linkid=2261819&templateid=WA200006518&templatetitle=AnacondaToolbox"
                style={{ textDecoration: "none" }}
              >
                <ArrowCircleRightRegular /> Launch Toolbox in Excel Online
              </a>
            </li>
            <li style={{ paddingTop: "20px" }}>
              <a
                href="https://appsource.microsoft.com/en-us/product/office/WA200006518"
                style={{ textDecoration: "none" }}
              >
                <ArrowCircleRightRegular /> View Anaconda Toolbox on AppSource
              </a>
            </li>
          </ul>
        </h3>
      </div>
    );
    return;
  }

  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <FluentProvider style={{ height: "100%" }} theme={lightTheme}>
          <DataFiltersProvider>
            <PythonContextProvider>
              <BlocksProvider>
                <ChartProvider>
                  {/* This basename allows app to render with filename in url to work with Excel add-in template */}
                  <BrowserRouter basename="/">
                    <CustomRouter />
                  </BrowserRouter>
                </ChartProvider>
              </BlocksProvider>
            </PythonContextProvider>
          </DataFiltersProvider>
        </FluentProvider>
        {/* Note: Uncomment for react-query dev tools -- Only in NODE_ENV == development */}
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </React.StrictMode>
  );
});

/**
 * This Router will store sub-routes to automatically navigate to when a tab is revisited
 * Note: This only applies to visualizations
 * @returns Routes
 */
function CustomRouter() {
  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<Start />} />
          {/* 
            /data/          (browse connections)
            /data/import    (download to workbook)
            /data/export    (save to cloud)
            /data/new-project
          */}
          <Route path="/back/" element={<Navigate to="/" replace={true} />} />
          <Route path="/data/import" element={<ConnectToWorkbook />} />
          <Route path="/data/export" element={<ConnectToCloud />} />
          <Route path="/data/new-project" element={<NewProject tag="data" />} />
          <Route path="/data/filter" element={<FilterPage tag="data" />} />
          <Route path="/data" element={<DataConnections />} />
          {/* Code Snippets */}
          {/* <Route path="/snippets/import" element={<ImportSnippet />} /> */}
          <Route path="/snippets/export/:mode?" element={<ExportSnippet />} />
          <Route path="/snippets/new-project" element={<NewProject tag="code_snippet" />} />
          <Route path="/snippets/filter" element={<FilterPage tag="code_snippet" />} />
          <Route path="/snippets" element={<Snippets />} />
          {/* Visualizations */}
          <Route path="/viz/create" element={<CreateVisualization />} />
          <Route path="/viz/edit" element={<CoreViz />} />
          <Route path="/viz" element={<Visualizations />} />
          {/* Example: 
            /ai/chat
            /ai/history
          */}
          <Route path="/ai/*" element={<AIAssistant />} />
          <Route path="/edublocks" element={<Edublocks />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
      </Routes>
      <FeedbackMenu />
    </ErrorBoundary>
  );
}
