import React, { useState, Suspense, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Tooltip } from "@fluentui/react-components";
import { Copy20Regular, CodeBlockRegular, EditRegular } from "@fluentui/react-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CodeMirror, { KeyBinding, Prec, ReactCodeMirrorRef, keymap } from "@uiw/react-codemirror";
import { acceptCompletion } from "@codemirror/autocomplete";
import { python } from "@codemirror/lang-python";
import { Row } from "../../components/Layout/Space";
import { getFullAddressFromRangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { writeToExecutionLocation } from "../../../excel/grid-utils";
import { buildPythonInExcelCode } from "./MVCShared/CodeBuilder";
import ActionButtons from "./ActionButtons";
import { editorStyles } from "./VizSharedComponents/Themes";
import { codeDarkTheme } from "../../theme";
import { pythonEditorSetup } from "xlcommon/src/pyscript/editor";
import { useChart, useChartManager } from "../../../taskpane/hooks/plots/useCentralViz";
import { BaseDesign, BaseSetup } from "../../../taskpane/hooks/plots/PlotTypes";
import { CustomDialog } from "../../../taskpane/components/Dialogs/CustomDialog";
import { WarningLabel } from "./DialogLabels";
import { useUserSetting } from "../../../queryclient/settings/settings";
import { UserSettings } from "../../../queryclient/settings/types";
import "./Code.scss";

const Code = () => {
  const { plotType } = useChartManager();
  const { common, codeFragments, updateCommon, serializeContext } = useChart<BaseSetup, BaseDesign>(plotType);
  const { data: warningSeen = undefined } = useUserSetting(UserSettings.EditCodeWarning.Seen);
  const [warningDialogOpen, setWarningDialogOpen] = useState<boolean>(false);
  const cm = useRef<ReactCodeMirrorRef>();
  const navigate = useNavigate();

  if (!common.inputData) {
    return <p data-testid="preview-message">Select input data in the setup tab to view code.</p>;
  }

  const handleCreateClick = async () => {
    const addr = await getFullAddressFromRangeSelection(common.outputCell);
    if (common.typedCode) {
      await writeToExecutionLocation(addr, common.typedCode);
    } else {
      await writeToExecutionLocation(addr, uneditedCode);
    }
    await serializeContext();
  };

  const [uneditedCode, setUneditedCode] = useState<string>();
  useEffect(() => {
    if (!common.typedCode) {
      (async () => {
        setUneditedCode(await buildPythonInExcelCode(codeFragments));
      })();
    }
  }, []);

  const customKeyBindings: KeyBinding[] = [{ key: "Tab", run: acceptCompletion, preventDefault: false }];

  function editorRefCallback(editor: ReactCodeMirrorRef) {
    if (editor?.editor && editor?.state && editor?.view) {
      cm.current = editor; // Save reference
    }
  }

  return (
    <div className="code" style={{ height: "100%" }}>
      <Suspense>
        <Row justifyContent="space-between" alignItems="center">
          <b data-testid="readonly-header">{common.typedCode ? "Edit Code" : "Preview Code (read-only)"}</b>
          <div style={{ display: "flex", gap: 5 }}>
            {!common.typedCode && (
              <>
                <Tooltip content="Edit code" relationship="label" withArrow>
                  <Button
                    appearance="subtle"
                    icon={<EditRegular />}
                    onClick={() => {
                      if (warningSeen) {
                        updateCommon({ typedCode: uneditedCode });
                      } else {
                        setWarningDialogOpen(true);
                      }
                    }}
                  />
                </Tooltip>
              </>
            )}
            <CustomDialog
              openDialog={warningDialogOpen}
              setOpenDialog={(open) => setWarningDialogOpen(open)}
              title="Warning"
              content={<WarningLabel />}
              onContinue={() => {
                updateCommon({ typedCode: uneditedCode });
              }}
            />
            <Tooltip content="Copy code" relationship="label" withArrow>
              <div>
                {" "}
                {/* This div is necessary to prevent the Tooltip from conflicting with CopyToClipboard component listeners simply */}
                <CopyToClipboard text={common.typedCode ?? uneditedCode}>
                  <Button appearance="subtle" icon={<Copy20Regular />} />
                </CopyToClipboard>
              </div>
            </Tooltip>
            <Tooltip content="Save as code snippet" relationship="label" withArrow>
              <Button
                appearance="subtle"
                icon={<CodeBlockRegular />}
                onClick={() => navigate("/snippets/export", { state: { code: common.typedCode ?? uneditedCode } })}
              />
            </Tooltip>
          </div>
        </Row>
        <CodeMirror
          value={common.typedCode ?? uneditedCode}
          editable={!!common.typedCode}
          readOnly={!common.typedCode}
          ref={editorRefCallback}
          basicSetup={pythonEditorSetup}
          theme={codeDarkTheme}
          extensions={[Prec.highest(keymap.of(customKeyBindings)), python(), editorStyles]}
          height="100%"
          style={{ height: "calc(100% - 50px)" }}
          data-testid="readonly-editor"
          onChange={(value) => {
            updateCommon({ typedCode: value });
          }}
        />
        <ActionButtons onPrimaryClick={handleCreateClick} />
      </Suspense>
    </div>
  );
};

export default Code;
