import { useEffect } from "react";
import { PlotType } from "../../../../data/plot-types";
import { RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { useChart } from "../../../../taskpane/hooks/plots/useCentralViz";
import { dependencyEqualsValue, dependencyNotEqualsValue, fetchHeaders } from "../MVCShared/PlotGeneratorUtils";
import { Chart } from "../MVCShared/types";
import {
  DataRangeAttr,
  DividerAttr,
  HeadingAttr,
  LabelAttr,
  PaletteAttr,
  CheckBoxAttr,
  SpinnerAttr,
  ColorPickerAttr,
  CollapsibleAttr,
  AxisDropdownAttr,
  GridlinesAttr,
  SingleChartBorders,
  DropdownAttr,
} from "../MVCShared/PlotAttributes";
import { buildReactFromAttrs, buildCode, CodeBuilder } from "../MVCShared/CodeBuilder";
import { KdeSetup, KdeDesign as IKdeDesign } from "src/taskpane/hooks/plots/useKde";

const KdeContext = (): Chart => {
  const { setup, design, common, updateDesign, updateSetup, updateCommon, setCodeFragments } = useChart<
    KdeSetup,
    IKdeDesign
  >(PlotType.kde);

  useEffect(() => {
    (async () => {
      const cb = await buildCode(common, [...KdePlot.baseAttrs, ...KdePlot.designAttrs]);
      setCodeFragments(cb);
    })();
  }, [setup, design, common]);

  useEffect(() => {
    (async () => {
      await fetchHeaders(common.inputData, common.hasHeaders, updateSetup);
    })();
  }, [common.hasHeaders, common.inputData]);

  const xAxis = AxisDropdownAttr({
    value: setup.xAxis,
    onChange: (_, data) => {
      updateSetup({ xAxis: data.optionValue }, "--Select--");
      updateDesign({ xAxisLabel: data.optionValue }, "--Select--");
    },
    label: "X-Axis",
    options: ["--Select--", ...setup.headers],
    codeKey: "x",
    hasHeaders: common.hasHeaders,
  });

  const BandwidthAttr = DropdownAttr({
    value: setup.bwMethod,
    onChange: (_, data) => updateSetup({ bwMethod: data.optionValue }),
    label: "Bandwidth",
    options: ["Scott", "Silverman", "Custom"],
    codeKey: "bw_method",
    codeRequiresInteraction: true,
    dataTestID: "bandwidth",
  });

  BandwidthAttr.getCode = (code: CodeBuilder) => {
    if (setup.bwMethod.isUpdated === true) {
      setup.bwMethod.value === "Custom"
        ? null
        : code.plotAttrs.push(`bw_method="${setup.bwMethod.value.toLowerCase()}"`);
    }
  };

  const ColorBy = AxisDropdownAttr({
    value: setup.colorBy,
    onChange: (_, data) => updateSetup({ colorBy: data.optionValue }, "--None--"),
    label: "Color By",
    options: ["--None--", ...setup.headers],
    placeholder: "--None--",
    codeKey: "hue",
    hasHeaders: common.hasHeaders,
  });

  const KdePlot: Chart = {
    baseAttrs: [
      DataRangeAttr({
        inputData: common.inputData,
        onChangeSelection: (newSelection: RangeSelection) => updateCommon({ inputData: newSelection }),
      }),
      CheckBoxAttr({
        label: "Has headers",
        value: common.hasHeaders,
        onChange: (_, e) => updateCommon({ hasHeaders: e.checked }),
        dataTestID: "headers",
      }),
      DividerAttr(),
      HeadingAttr({
        title: "Data",
        tooltip: "Select data cells and parameters",
      }),
      xAxis,
      AxisDropdownAttr({
        value: setup.yAxis,
        onChange: (_, data) => {
          updateSetup({ yAxis: data.optionValue }, "--Select--");
          updateDesign({ yAxisLabel: data.optionValue }, "--Select--");
        },
        label: "Y-Axis",
        options: ["--Select--", ...setup.headers],
        codeKey: "y",
        hasHeaders: common.hasHeaders,
      }),
      AxisDropdownAttr({
        value: setup.weights,
        onChange: (_, data) => {
          updateSetup({ weights: data.optionValue }, "--Select--");
        },
        label: "Weights",
        options: ["--Select--", ...setup.headers],
        codeKey: "weights",
        hasHeaders: common.hasHeaders,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Grouping", tooltip: "Determine violin color" }),
      ColorBy,
      DropdownAttr({
        label: "Multiple",
        options: ["Layer", "Dodge", "Stack", "Fill"],
        value: setup.multiple,
        codeKey: "multiple",
        codeValueMap: { Layer: "layer", Stack: "stack", Fill: "fill" },
        codeRequiresInteraction: true,
        onChange: (_, data) => updateSetup({ multiple: data.optionValue }),
      }),
      DividerAttr(),
      CollapsibleAttr({
        label: "Kernel",
        tooltip: "Compute kernel density estimate",
        collapsed: setup.isCollapsed,
        toggle: () => updateSetup({ isCollapsed: !setup.isCollapsed }),
        children: [
          BandwidthAttr,
          SpinnerAttr({
            label: "Custom Adjust",
            value: setup.bwAdjust,
            step: 1,
            max: 10,
            min: 0,
            onChange: (data: number) => updateSetup({ bwAdjust: data }),
            codeKey: "bw_adjust",
            visibleDependencies: [dependencyEqualsValue(BandwidthAttr, "Custom")],
            codeRequiresInteraction: true,
          }),
          SpinnerAttr({
            label: "Cut",
            value: setup.cut,
            step: 1,
            onChange: (data: number) => updateSetup({ cut: data }),
            codeKey: "cut",
            codeRequiresInteraction: true,
            dataTestID: "cut",
          }),
          SpinnerAttr({
            label: "Grid Size",
            value: setup.gridSize,
            step: 10,
            onChange: (data: number) => updateSetup({ gridSize: data }),
            codeKey: "gridsize",
            codeRequiresInteraction: true,
            dataTestID: "grid",
          }),
        ],
      }),
      DividerAttr(),
      SpinnerAttr({
        label: "Log Scale",
        value: setup.logScale,
        step: 10,
        max: 100,
        min: 0,
        onChange: (data: number) => updateSetup({ logScale: data }),
        codeKey: "log_scale",
        codeRequiresInteraction: true,
      }),
    ],
    designAttrs: [
      LabelAttr({
        value: design.plotTitle,
        placeholder: "Title",
        label: "Title",
        codeKey: "title",
        onChange: (event) => updateDesign({ plotTitle: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.xAxisLabel,
        placeholder: "Defaults header",
        label: "X-Axis Label",
        codeKey: "xlabel",
        onChange: (event) => updateDesign({ xAxisLabel: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.yAxisLabel,
        placeholder: "Defaults header",
        label: "Y-Axis Label",
        codeKey: "ylabel",
        onChange: (event) => updateDesign({ yAxisLabel: event.currentTarget.value }),
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.borderCollapsed,
        label: "Border",
        toggle: () => {
          updateDesign({ borderCollapsed: !design.borderCollapsed });
        },
        children: [
          SingleChartBorders({
            label: "Top",
            value: design.topSpine,
            onChange: (_, e) => updateDesign({ topSpine: e.checked }),
            callKey: "top",
          }),
          SingleChartBorders({
            label: "Right",
            value: design.rightSpine,
            onChange: (_, e) => updateDesign({ rightSpine: e.checked }),
            callKey: "right",
          }),
          SingleChartBorders({
            label: "Bottom",
            value: design.bottomSpine,
            onChange: (_, e) => updateDesign({ bottomSpine: e.checked }),
            callKey: "bottom",
          }),
          SingleChartBorders({
            label: "Left",
            value: design.leftSpine,
            onChange: (_, e) => updateDesign({ leftSpine: e.checked }),
            callKey: "left",
          }),
        ],
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.gridlinesCollapsed,
        label: "Gridlines",
        toggle: () => {
          updateDesign({ gridlinesCollapsed: !design.gridlinesCollapsed });
        },
        children: [
          GridlinesAttr({
            majorHorizontal: design.majorHorizontal,
            majorVertical: design.majorVertical,
            minorHorizontal: design.minorHorizontal,
            minorVertical: design.minorVertical,
            onChange: (key, event) => {
              updateDesign({ [key]: event.checked });
            },
          }),
        ],
      }),
      DividerAttr(),
      HeadingAttr({ title: "Axes Label Rotation" }),
      SpinnerAttr({
        label: "X-Ticks",
        value: design.xticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (data: number) => updateDesign({ xticks: data }),
        callKey: "xticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Y-Ticks",
        value: design.yticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (data: number) => updateDesign({ yticks: data }),
        callKey: "yticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      SpinnerAttr({
        label: "Threshold",
        value: design.thresh,
        step: 0.1,
        max: 1,
        min: 1,
        onChange: (data: number) => updateDesign({ thresh: data }),
        callKey: "thresh",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      ColorPickerAttr({
        value: design.color,
        label: "Color",
        onChange: (hexColor) => updateDesign({ color: `#${hexColor}` }),
        codeKey: "color",
        visibleDependencies: [dependencyEqualsValue(ColorBy, "")],
        codeRequiresInteraction: true,
      }),
      CheckBoxAttr({
        value: design.fill,
        onChange: (_, e) => updateDesign({ fill: e.checked }),
        label: "Fill",
        codeKey: "fill",
        codeRequiresInteraction: true,
      }),
      PaletteAttr({
        value: design.palette,
        onChange: (_, data) => updateDesign({ palette: data.optionText }),
        codeKey: "palette",
        placeholder: "Accent",
        visibleDependencies: [dependencyNotEqualsValue(ColorBy, "")],
        codeRequiresInteraction: true,
      }),

      DividerAttr(),
    ],
  };
  return KdePlot;
};

const KdeForm = () => {
  const KdePlot = KdeContext();
  return buildReactFromAttrs(KdePlot.baseAttrs, 100);
};

export const KdeDesign = () => {
  const KdeDesign = KdeContext();
  return buildReactFromAttrs(KdeDesign.designAttrs, 120);
};

export default KdeForm;
