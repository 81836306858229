import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Tooltip } from "@fluentui/react-components";
import { DocumentCopyRegular, Play16Filled, EditRegular, DeleteRegular } from "@fluentui/react-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ConfirmationDialog } from "../../components/Dialogs/ConfirmationDialog";
import { Row } from "../../components/Layout/Space";
import CodeMirror from "@uiw/react-codemirror";
import { python } from "@codemirror/lang-python";
import { pythonEditorSetup } from "xlcommon/src/pyscript/editor";
import { ExportSnippetProps } from "./ExportSnippet";
import { writeToExecutionLocation } from "../../../excel/grid-utils";
import ProjectsService from "../../../data/projects/projects-api";
import { Project } from "../../../data/projects/models";
import { codeDarkTheme } from "../../theme";
import {
  RangeType,
  getAliasFromRangeSelection,
  writeToCell,
  getSplitIdAddrFromBinding,
} from "xlcommon/src/excel/excel-grid-utils";

export const SnippetViewer = ({
  project,
  code,
  name,
  onDelete,
}: {
  project: Project;
  code: string;
  name: string;
  onDelete: () => Promise<void>;
}) => {
  const stateCode = useRef<string>("");
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

  async function addCodeToContainer(code: string) {
    stateCode.current = code;
    const options = { promptText: "Click to select" };
    Office.context.document.bindings.addFromPromptAsync(Office.BindingType.Matrix, options, promptCallback);
  }

  async function promptCallback(result) {
    if (result.value === undefined) {
      console.log("User cancelled the dialog");
      return;
    }

    let code = stateCode.current;

    const targetAddress = await getAliasFromRangeSelection({
      rangeType: RangeType.CellBinding,
      identifier: result.value.id,
    });
    // Easy way to delete the binding id as a side effect
    await getSplitIdAddrFromBinding(result.value.id, /*deleteId=*/ true);

    if (name.endsWith(".py")) {
      await writeToExecutionLocation(targetAddress, code);
    } else {
      await writeToCell(targetAddress, code, false);
    }
  }

  const [disabled, setDisabled] = useState<boolean>(true);
  async function checkHasModifyAccess() {
    const access = await ProjectsService.getMyProjectAccessLevels(project.id);
    setDisabled(access?.modify === false);
    return access;
  }
  useEffect(() => {
    (async () => {
      await checkHasModifyAccess();
    })();
  }, []);

  const navigate = useNavigate();
  async function openEditor() {
    const access = await checkHasModifyAccess();
    if (access?.modify === false) return;
    navigate(`/snippets/export/edit`, {
      state: {
        code,
        project,
        fileName: name,
      } as ExportSnippetProps,
    });
  }

  async function openConfirmationIfAccess() {
    const access = await checkHasModifyAccess();
    if (access?.modify === false) return;
    setOpenConfirmation(true);
  }

  return (
    <>
      <CodeMirror
        editable={false}
        height="100%"
        value={code}
        basicSetup={pythonEditorSetup}
        theme={codeDarkTheme}
        extensions={[python()]}
        data-testid="code-snippet-viewer"
      />
      <div style={{ paddingTop: 8, paddingBottom: 8 }}>
        <Row justifyContent="flex-end" gap={10}>
          <Tooltip
            content={disabled ? "Requires write permissions to delete" : "Delete"}
            relationship="label"
            withArrow
          >
            <Button
              appearance="subtle"
              icon={<DeleteRegular />}
              disabled={disabled}
              onClick={async () => await openConfirmationIfAccess()}
            />
          </Tooltip>
          <Tooltip content={disabled ? "Requires write permissions to edit" : "Edit"} relationship="label" withArrow>
            <Button
              appearance="subtle"
              icon={<EditRegular />}
              disabled={disabled}
              onClick={async () => await openEditor()}
            />
          </Tooltip>
          <Tooltip content="Copy" relationship="label" withArrow>
            <div>
              {" "}
              {/* This div is necessary to prevent the Tooltip from conflicting with CopyToClipboard component listeners simply */}
              <CopyToClipboard text={code}>
                <Button appearance="subtle" icon={<DocumentCopyRegular />} />
              </CopyToClipboard>
            </div>
          </Tooltip>
          <Tooltip content="Run in cell" relationship="label" withArrow>
            <Button
              className="action-button primary"
              onClick={async () => await addCodeToContainer(code)}
              appearance="primary"
              icon={<Play16Filled />}
              iconPosition="before"
            />
          </Tooltip>
        </Row>
        <ConfirmationDialog
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          deleteCallback={async () => await onDelete()}
        />
      </div>
    </>
  );
};
